define("ember-cp-validations/-private/options", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/classCallCheck", "ember-cp-validations/utils/utils"], function (_exports, _defineProperty2, _classCallCheck2, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var keys = Object.keys;
  var OPTION_KEYS = '__option_keys__';
  var OptionsObject = Ember.Object.extend({
    toObject: function toObject() {
      var _this = this;

      return this[OPTION_KEYS].reduce(function (obj, key) {
        obj[key] = Ember.get(_this, key);
        return obj;
      }, {});
    }
  });

  var Options = function Options(_ref) {
    var _createParams;

    var model = _ref.model,
        attribute = _ref.attribute,
        _ref$options = _ref.options,
        options = _ref$options === void 0 ? {} : _ref$options;
    (0, _classCallCheck2.default)(this, Options);
    var optionKeys = keys(options);
    var createParams = (_createParams = {}, (0, _defineProperty2.default)(_createParams, OPTION_KEYS, optionKeys), (0, _defineProperty2.default)(_createParams, "model", model), (0, _defineProperty2.default)(_createParams, "attribute", attribute), _createParams); // If any of the options is a CP, we need to create a custom class for it

    if (optionKeys.some(function (key) {
      return (0, _utils.isDescriptor)(options[key]);
    })) {
      return OptionsObject.extend(options).create(createParams);
    }

    return OptionsObject.create(createParams, options);
  };

  _exports.default = Options;
});