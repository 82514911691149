define("ember-css-modules/mixins/component-mixin", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "@babel/runtime/helpers/esm/toConsumableArray"], function (_exports, _slicedToArray2, _toConsumableArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    localClassNames: null,
    localClassNameBindings: null,
    concatenatedProperties: ['localClassNames', 'localClassNameBindings'],
    init: function init() {
      this._super();

      if (this.tagName === '') return;
      this.classNameBindings = [].concat((0, _toConsumableArray2.default)(this.classNameBindings), (0, _toConsumableArray2.default)(localClassNames(this)), [LOCAL_CLASS_NAMES_CP]);

      if (this.localClassNameBindings.length) {
        var value = localClassNamesCP(this.localClassNameBindings, this.get('__styles__'));
        Ember.defineProperty(this, LOCAL_CLASS_NAMES_CP, value);
      }
    },
    __styles__: Ember.computed(function () {
      var key = this._debugContainerKey;

      if (!key) {
        return;
      }

      var name = key.replace(/^component:/, '');
      var layout = this.layout || Ember.getOwner(this).lookup("template:components/".concat(name));
      (false && !(layout) && Ember.assert("Unable to resolve localClassNames or localClassNameBindings for component ".concat(name, ", which has no ") + "layout. You can fix this by either creating an empty template for your component or importing and " + "using the styles hash directly instead, e.g. `classNames: styles['my-class']`.", layout)); // Since https://github.com/emberjs/ember.js/pull/18096

      if (typeof layout === 'function') layout = layout(Ember.getOwner(this)); // This is not public API and might break at any time...

      var moduleName = (layout.meta || layout.referrer).moduleName.replace(/\.hbs$/, '');

      if (/\/template$/.test(moduleName)) {
        return tryLoad(moduleName.replace(/template$/, 'styles'));
      } else if (/\/templates\//.test(moduleName)) {
        return tryLoad(moduleName.replace('/templates/', '/styles/'));
      }

      return;
    })
  });

  _exports.default = _default;

  function tryLoad(path) {
    if (require.has(path)) {
      return require(path).default;
    }
  }

  var LOCAL_CLASS_NAMES_CP = '__ecm_local_class_names__';

  function localClassNames(component) {
    return component.localClassNames.map(function (className) {
      return "__styles__.".concat(className);
    });
  }

  function localClassNamesCP(localClassNameBindings, styles) {
    var bindings = localClassNameBindings.map(function (binding) {
      var _binding$split = binding.split(':'),
          _binding$split2 = (0, _slicedToArray2.default)(_binding$split, 3),
          property = _binding$split2[0],
          trueStyle = _binding$split2[1],
          falseStyle = _binding$split2[2];

      var trueClasses = styles[trueStyle || Ember.String.dasherize(property)] || '';
      var falseClasses = styles[falseStyle] || '';
      var isBoolean = !!trueStyle;
      return {
        property: property,
        trueClasses: trueClasses,
        falseClasses: falseClasses,
        isBoolean: isBoolean
      };
    });
    return Ember.computed.apply(void 0, (0, _toConsumableArray2.default)(bindings.map(function (binding) {
      return binding.property;
    })).concat([function () {
      var _this = this;

      var classes = [];
      bindings.forEach(function (binding) {
        var value = _this.get(binding.property);

        if (binding.isBoolean || typeof value !== 'string') {
          classes.push(value ? binding.trueClasses : binding.falseClasses);
        } else {
          classes.push(value.split(/\s+/).map(function (key) {
            return styles[key];
          }).join(' '));
        }
      });
      return classes.join(' ');
    }]));
  }
});