define("ember-css-modules/decorators", ["exports", "@babel/runtime/helpers/esm/typeof", "@babel/runtime/helpers/esm/slicedToArray", "@babel/runtime/helpers/esm/toConsumableArray"], function (_exports, _typeof2, _slicedToArray2, _toConsumableArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.localClassName = _exports.localClassNames = void 0;

  /**
    Class decorator which specifies the local  class names to be applied to a
    component. This replaces the `localClassNames` property on components in the
    traditional Ember object model.
  
    ```js
    @localClassNames('a-static-class', 'another-static-class')
    export default class ClassNamesDemoComponent extends Component {}
    ```
  
    @param {...string} classNames - The list of local classes to be applied to the component
  */
  var localClassNames = function localClassNames() {
    for (var _len = arguments.length, classNames = new Array(_len), _key = 0; _key < _len; _key++) {
      classNames[_key] = arguments[_key];
    }

    return function () {
      (false && !(classNames.every(function (className) {
        return typeof className === 'string';
      })) && Ember.assert("The @localClassNames decorator must be provided strings, received: ".concat(classNames), classNames.every(function (className) {
        return typeof className === 'string';
      })));

      for (var _len2 = arguments.length, desc = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        desc[_key2] = arguments[_key2];
      }

      if (isStage1ClassDescriptor(desc)) {
        collapseAndMerge.apply(void 0, [desc[0].prototype, 'localClassNames'].concat(classNames));
      } else {
        desc[0].finisher = function (target) {
          collapseAndMerge.apply(void 0, [target.prototype, 'localClassNames'].concat(classNames));
        };
      }
    };
  };
  /**
    Decorator which indicates that the field or computed should be bound to the
    component local class names. This replaces `localClassNameBindings` by
    directly allowing you to specify which properties should be bound.
  
    ```js
    export default class ClassNameDemoComponent extends Component {
      // Adds 'bound-field' class, if true
      @localClassName boundField = true;
  
      // With provided true/false class names
      @localClassName('active', 'inactive') isActive = true;
    }
    ```
    @function
    @param {string} truthyName? - The local class to be applied if the value the
                                  field is truthy, defaults to the name of the
                                  field.
    @param {string} falsyName? - The class to be applied if the value of the field
                                 is falsy.
  */


  _exports.localClassNames = localClassNames;

  var localClassName = function localClassName() {
    for (var _len3 = arguments.length, params = new Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
      params[_key3] = arguments[_key3];
    }

    if (isFieldDescriptor(params)) {
      return localClassName().apply(void 0, params);
    }

    return function () {
      for (var _len4 = arguments.length, desc = new Array(_len4), _key4 = 0; _key4 < _len4; _key4++) {
        desc[_key4] = arguments[_key4];
      }

      (false && !(params.every(function (className) {
        return typeof className === 'string';
      })) && Ember.assert("The @localClassName decorator may only receive strings as parameters. Received: ".concat(params), params.every(function (className) {
        return typeof className === 'string';
      })));

      if (isStage1FieldDescriptor(desc)) {
        var prototype = desc[0],
            key = desc[1],
            descriptor = desc[2];
        setUpLocalClassField(params, prototype, key, descriptor);
      } else if (isStage2FieldDescriptor(desc)) {
        desc[0].finisher = function (target) {
          var _desc$ = desc[0],
              key = _desc$.key,
              descriptor = _desc$.descriptor;
          setUpLocalClassField(params, target.prototype, key, descriptor);
        };
      }
    };
  };

  _exports.localClassName = localClassName;

  function setUpLocalClassField(params, prototype, key, descriptor) {
    var binding = params.length > 0 ? "".concat(key, ":").concat(params.join(':')) : key;
    collapseAndMerge(prototype, 'localClassNameBindings', binding);

    if (descriptor) {
      // Decorated fields are currently not configurable in Babel for some reason, so ensure
      // that the field becomes configurable (else it messes with things)
      descriptor.configurable = true; // Decorated fields which don't have a getter or setter, but also no
      // explicit `writable` flag, default to not being writable in Babel. Since
      // by default fields _are_ writable and this decorator should not change
      // that, we enable the `writable` flag in this specific case.

      if (!('get' in descriptor || 'set' in descriptor || 'writable' in descriptor)) {
        descriptor.writable = true;
      } // Babel 6 provides a `null` initializer if one isn't set, but that can wind up
      // overwriting passed-in values if they're specified.
      // This is a no-op in Babel 7 (since `initializer` isn't part of the property descriptor)
      // and can be dropped when we remove support for Babel 6


      if (descriptor.initializer === null) {
        descriptor.initializer = function () {
          return Ember.get(this, key);
        };
      }
    }
  }

  function collapseAndMerge(prototype, property) {
    collapseProto(prototype);

    for (var _len5 = arguments.length, items = new Array(_len5 > 2 ? _len5 - 2 : 0), _key5 = 2; _key5 < _len5; _key5++) {
      items[_key5 - 2] = arguments[_key5];
    }

    if (property in prototype) {
      var parentElements = prototype[property];
      items.unshift.apply(items, (0, _toConsumableArray2.default)(parentElements));
    }

    prototype[property] = items;
  } // These utilities are from @ember-decorators/utils
  // https://github.com/ember-decorators/ember-decorators/blob/f3e3d636a38d99992af326a1012d69bf10a2cb4c/packages/utils/addon/-private/class-field-descriptor.js


  function isStage1ClassDescriptor(possibleDesc) {
    var _possibleDesc = (0, _slicedToArray2.default)(possibleDesc, 1),
        target = _possibleDesc[0];

    return possibleDesc.length === 1 && typeof target === 'function' && 'prototype' in target && !target.__isComputedDecorator;
  }

  function isFieldDescriptor(possibleDesc) {
    return isStage1FieldDescriptor(possibleDesc) || isStage2FieldDescriptor(possibleDesc);
  }

  function isStage2FieldDescriptor(possibleDesc) {
    return possibleDesc && possibleDesc.toString() === '[object Descriptor]';
  }

  function isStage1FieldDescriptor(possibleDesc) {
    var _possibleDesc2 = (0, _slicedToArray2.default)(possibleDesc, 3),
        target = _possibleDesc2[0],
        key = _possibleDesc2[1],
        desc = _possibleDesc2[2];

    return possibleDesc.length === 3 && (0, _typeof2.default)(target) === 'object' && target !== null && typeof key === 'string' && ((0, _typeof2.default)(desc) === 'object' && desc !== null && 'enumerable' in desc && 'configurable' in desc || desc === undefined) // TS compatibility
    ;
  }

  function collapseProto(target) {
    // We must collapse the superclass prototype to make sure that the `actions`
    // object will exist. Since collapsing doesn't generally happen until a class is
    // instantiated, we have to do it manually.
    if (typeof target.constructor.proto === 'function') {
      target.constructor.proto();
    }
  }
});