define('ember-cli-braintree/components/braintree-dropin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    authorization: null,
    braintreeOptions: null,

    didInsertElement: function didInsertElement() {
      this.attach();
    },
    willDestroy: function willDestroy() {
      this.teardown();
    },
    callIfAvailable: function callIfAvailable(methodName, arg) {
      var method = this.get(methodName);

      if (method && typeof method === 'function') {
        method(arg);
      }
    },
    attach: function attach() {
      var _this = this;

      var authorization = this.get('authorization'),
          opts = this.get('braintreeOptions') || {},
          callReq = this.get('callOnRequestable') === true || this.get('onNonce');

      if (!authorization) {
        return;
      }

      braintree.dropin.create(Object.assign({
        authorization: authorization,
        container: '#' + this.get('elementId')
      }, opts)).then(function (instance) {
        _this.set('__btInstance', instance);

        _this.callIfAvailable('updateRequestable', instance.requestPaymentMethod);
        _this.callIfAvailable('updateInstance', instance);

        if (callReq || _this.get('onRequestable')) {
          instance.on('paymentMethodRequestable', function (event) {
            _this.callIfAvailable('onRequestable', event);

            if (callReq) {
              instance.requestPaymentMethod().then(function (payload) {
                return _this.callIfAvailable('onNonce', payload.nonce);
              }).catch(function (err) {
                return _this.callIfAvailable('onNonceError', err);
              });
            }
          });
        }
      });
    },
    teardown: function teardown() {
      var btInstance = this.get('__btInstance');

      if (btInstance && btInstance.teardown) {
        btInstance.teardown();
      }
    }
  });
});