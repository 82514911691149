define("ember-lifeline/utils/get-task", ["exports", "@babel/runtime/helpers/esm/typeof"], function (_exports, _typeof2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getTask;

  /**
   * @function getTask
   * @param obj
   * @param taskOrName
   * @param taskName
   */
  function getTask(obj, taskOrName, taskName) {
    var type = (0, _typeof2.default)(taskOrName);
    var task;

    if (type === 'function') {
      task = taskOrName;
    } else if (type === 'string') {
      task = obj[taskOrName];

      if (typeof task !== 'function') {
        throw new TypeError("The method name '".concat(taskOrName, "' passed to ").concat(taskName, " does not resolve to a valid function."));
      }
    } else {
      throw new TypeError("You must pass a task function or method name to '".concat(taskName, "'."));
    }

    return task;
  }
});