define("ember-cli-emoji/helpers/emoji-icon", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.emojiIcon = void 0;
  var EMOJI_PREFIX = /^emoji-.+/;
  /**
   * Handlebars helper for generating HTML that renders a Emoji icon.
   *
   * @param  {String} name   The icon name. For `emoji-smile`, just set `smile`.
   * @param  {Object} params Options passed to helper.
   * @return {Ember.Handlebars.SafeString} The HTML markup.
   */

  var emojiIcon = function emojiIcon(name) {
    var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
        classNames = _ref.classNames,
        tagName = _ref.tagName;

    if (Ember.typeOf(name) !== 'string') {
      var message = "emoji-icon: no icon specified";
      console.warn(message);
      return Ember.String.htmlSafe(message);
    }

    if (classNames === undefined) {
      classNames = [];
    }

    if (!Ember.isArray(classNames)) {
      classNames = [classNames];
    }

    classNames.push("emoji");
    var htmlTitle = name ? "title=\":".concat(name, ":\"") : "";

    if (!name.match(EMOJI_PREFIX)) {
      name = "emoji-".concat(name);
    }

    classNames.push(name);
    tagName = tagName || 'span';
    var htmlClass = "class=\"".concat(classNames.join(" "), "\"");
    var html = "<".concat(tagName, " ").concat(htmlTitle, " ").concat(htmlClass, "></").concat(tagName, ">");
    return html.replace(/\s+/g, " ");
  };

  _exports.emojiIcon = emojiIcon;

  var _default = Ember.Helper.helper(function emojiIconHelper(_ref2, params) {
    var _ref3 = (0, _slicedToArray2.default)(_ref2, 1),
        name = _ref3[0];

    return Ember.String.htmlSafe(emojiIcon(name, params));
  });

  _exports.default = _default;
});