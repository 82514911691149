define("ember-lifeline/debounce-task", ["exports", "ember-lifeline/utils/disposable"], function (_exports, _disposable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.debounceTask = debounceTask;
  _exports.cancelDebounce = cancelDebounce;

  /**
   * A map of instances/debounce functions that allows us to
   * store pending debounces per instance.
   *
   * @private
   */
  var registeredDebounces = new WeakMap();
  /**
   * Runs the function with the provided name after the timeout has expired on the last
   * invocation. The timer is properly canceled if the object is destroyed before it is
   * invoked.
   *
   * Example:
   *
   * ```js
   * import Component from 'ember-component';
   * import { debounceTask, runDisposables } from 'ember-lifeline';
   *
   * export default Component.extend({
   *   logMe() {
   *     console.log('This will only run once every 300ms.');
   *   },
   *
   *   click() {
   *     debounceTask(this, 'logMe', 300);
   *   },
   *
   *   willDestroy() {
   *     this._super(...arguments);
   *
   *     runDisposables(this);
   *   }
   * });
   * ```
   *
   * @function debounceTask
   * @param { IDestroyable } destroyable the instance to register the task for
   * @param { String } name the name of the task to debounce
   * @param { ...* } debounceArgs arguments to pass to the debounced method
   * @param { Number } spacing the amount of time to wait before calling the method (in milliseconds)
   * @param { Boolean } [immediate] Trigger the function on the leading instead of the trailing edge of the wait interval. Defaults to false.
   * @public
   */

  function debounceTask(destroyable, name) {
    (false && !(typeof name === 'string') && Ember.assert("Called `debounceTask` without a string as the first argument on ".concat(destroyable, "."), typeof name === 'string'));
    (false && !(typeof destroyable[name] === 'function') && Ember.assert("Called `destroyable.debounceTask('".concat(name, "', ...)` where 'destroyable.").concat(name, "' is not a function."), typeof destroyable[name] === 'function'));

    if (destroyable.isDestroying) {
      return;
    }

    for (var _len = arguments.length, debounceArgs = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
      debounceArgs[_key - 2] = arguments[_key];
    }

    var lastArgument = debounceArgs[debounceArgs.length - 1];
    var spacing = typeof lastArgument === 'boolean' ? debounceArgs[debounceArgs.length - 2] : lastArgument;
    (false && !(typeof spacing === 'number') && Ember.assert("Called `debounceTask` with incorrect `spacing` argument. Expected Number and received `".concat(spacing, "`"), typeof spacing === 'number'));
    var pendingDebounces = registeredDebounces.get(destroyable);

    if (!pendingDebounces) {
      pendingDebounces = new Map();
      registeredDebounces.set(destroyable, pendingDebounces);
      (0, _disposable.registerDisposable)(destroyable, getDebouncesDisposable(pendingDebounces));
    }

    var debouncedTask;

    if (!pendingDebounces.has(name)) {
      debouncedTask = function debouncedTask() {
        pendingDebounces.delete(name);
        destroyable[name].apply(destroyable, arguments);
      };
    } else {
      debouncedTask = pendingDebounces.get(name).debouncedTask;
    } // cancelId is new, even if the debounced function was already present


    var cancelId = Ember.run.debounce.apply(void 0, [destroyable, debouncedTask].concat(debounceArgs));
    pendingDebounces.set(name, {
      debouncedTask: debouncedTask,
      cancelId: cancelId
    });
  }
  /**
   * Cancel a previously debounced task.
   *
   * Example:
   *
   * ```js
   * import Component from 'ember-component';
   * import { debounceTask, cancelDebounce } from 'ember-lifeline';
   *
   * export default Component.extend({
   *   logMe() {
   *     console.log('This will only run once every 300ms.');
   *   },
   *
   *   click() {
   *     debounceTask(this, 'logMe', 300);
   *   },
   *
   *   disable() {
   *      cancelDebounce(this, 'logMe');
   *   },
   *
   *   willDestroy() {
   *     this._super(...arguments);
   *
   *     runDisposables(this);
   *   }
   * });
   * ```
   *
   * @function cancelDebounce
   * @param { IDestroyable } destroyable the instance to register the task for
   * @param { String } methodName the name of the debounced method to cancel
   * @public
   */


  function cancelDebounce(destroyable, name) {
    if (!registeredDebounces.has(destroyable)) {
      return;
    }

    var pendingDebounces = registeredDebounces.get(destroyable);

    if (!pendingDebounces.has(name)) {
      return;
    }

    var _ref = pendingDebounces.get(name),
        cancelId = _ref.cancelId;

    pendingDebounces.delete(name);
    Ember.run.cancel(cancelId);
  }

  function getDebouncesDisposable(debounces) {
    return function () {
      if (debounces.size === 0) {
        return;
      }

      debounces.forEach(function (p) {
        return Ember.run.cancel(p.cancelId);
      });
    };
  }
});