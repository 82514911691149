define("ember-set-helper/helpers/set", ["exports", "@babel/runtime/helpers/esm/typeof", "@babel/runtime/helpers/esm/slicedToArray", "ember-set-helper/helpers/-set-placeholder"], function (_exports, _typeof2, _slicedToArray2, _setPlaceholder) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function set(positional) {
    var _positional = (0, _slicedToArray2.default)(positional, 3),
        target = _positional[0],
        key = _positional[1],
        valueOrPlaceholder = _positional[2];

    (false && !(positional.length > 2) && Ember.assert('you must pass a path and a value to the `(set)` helper. The value can be a defered value, using placeholder syntax, e.g. `(set this.value _)`', positional.length > 2));
    (false && !(positional.length === 3) && Ember.assert('you cannot pass more than a path and a value to set', positional.length === 3));
    (false && !(Boolean(target) && typeof key === 'string' || (0, _typeof2.default)(key) === 'symbol') && Ember.assert('you must pass a path to {{set}}', Boolean(target) && typeof key === 'string' || (0, _typeof2.default)(key) === 'symbol'));

    if (valueOrPlaceholder instanceof _setPlaceholder.Placeholder) {
      return function (_value) {
        var path = valueOrPlaceholder.path;
        var value = path === null ? _value : Ember.get(_value, path);
        return Ember.set(target, key, value);
      };
    } else {
      return function () {
        return Ember.set(target, key, valueOrPlaceholder);
      };
    }
  }

  var _default = Ember.Helper.helper(set);

  _exports.default = _default;
});