define("ember-event-helpers/helpers/stop-propagation", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.stopPropagation = stopPropagation;
  _exports.default = void 0;

  function stopPropagation(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
        handler = _ref2[0];

    (false && !(!handler || typeof handler === 'function') && Ember.assert("Expected '".concat(handler, "' to be a function, if present."), !handler || typeof handler === 'function'));
    return function (event) {
      (false && !(event && typeof event.stopPropagation === 'function') && Ember.assert("Expected '".concat(event, "' to be an Event and have a 'stopPropagation' method."), event && typeof event.stopPropagation === 'function'));
      event.stopPropagation();
      if (handler) handler(event);
    };
  }

  var _default = Ember.Helper.helper(stopPropagation);

  _exports.default = _default;
});