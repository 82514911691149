define("ember-cp-validations/-private/ember-validator", ["exports", "@babel/runtime/helpers/esm/typeof", "ember-cp-validations/validators/base", "ember-validators"], function (_exports, _typeof2, _base, _emberValidators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    validate: function validate() {
      var result = _emberValidators.validate.apply(void 0, [this.get('_evType')].concat(Array.prototype.slice.call(arguments)));

      if (result && (0, _typeof2.default)(result) === 'object') {
        return result.message ? result.message : this.createErrorMessage(result.type, result.value, result.context);
      }

      return result;
    }
  });

  _exports.default = _default;
});