define('ember-cli-notifications/components/notification-message', ['exports', 'ember-get-config', 'ember-cli-notifications/templates/components/notification-message', 'ember-cli-notifications/styles/components/notification-message'], function (exports, _emberGetConfig, _notificationMessage, _notificationMessage2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var iconGlobals = (_emberGetConfig.default['ember-cli-notifications'] || {}).iconClasses || {};

  var iconDefaults = {
    close: 'fa fa-times',
    info: 'fa fa-info-circle',
    success: 'fa fa-check',
    warning: 'fa fa-warning',
    error: 'fa fa-exclamation-triangle'
  };

  exports.default = Ember.Component.extend({
    layout: _notificationMessage.default,
    styles: _notificationMessage2.default,

    classNameBindings: ['dismissClass', 'clickableClass', 'processedType', 'notification.cssClasses'],

    attributeBindings: ['notification.type:data-test-notification-message'],

    paused: false,

    dismissClass: Ember.computed('notification.dismiss', function () {
      if (!this.get('notification.dismiss')) return this.get('styles.c-notification--in');

      return false;
    }),

    clickableClass: Ember.computed('notification.onClick', function () {
      if (this.get('notification.onClick')) return this.get('styles.c-notification--clickable');

      return false;
    }),

    closeIcon: Ember.computed(function () {
      return Ember.getWithDefault(iconGlobals, 'close', iconDefaults.close);
    }),

    // Set icon depending on notification type
    notificationIcon: Ember.computed('notification.type', function () {
      var type = this.get('notification.type');
      return type && Ember.getWithDefault(iconGlobals, type, iconDefaults[type]);
    }),

    mouseDown: function mouseDown() {
      if (this.get('notification.onClick')) {
        this.get('notification.onClick')(this.get('notification'));
      }
    },
    mouseEnter: function mouseEnter() {
      if (this.get('notification.autoClear')) {
        this.set('paused', true);
        this.notifications.pauseAutoClear(this.get('notification'));
      }
    },
    mouseLeave: function mouseLeave() {
      if (this.get('notification.autoClear')) {
        this.set('paused', false);
        this.notifications.setupAutoClear(this.get('notification'));
      }
    },


    processedType: Ember.computed('notification.type', function () {
      if (this.get('notification.type') && Ember.A(['info', 'success', 'warning', 'error']).includes(this.get('notification.type'))) {
        return this.get('styles.c-notification--' + this.get('notification.type'));
      }
    }),

    // Apply the clear animation duration rule inline
    notificationClearDuration: Ember.computed('paused', 'notification.clearDuration', function () {
      var duration = Ember.Handlebars.Utils.escapeExpression(this.get('notification.clearDuration'));
      var playState = this.get('paused') ? 'paused' : 'running';
      return Ember.String.htmlSafe('animation-duration: ' + duration + 'ms; -webkit-animation-duration: ' + duration + 'ms; animation-play-state: ' + playState + '; -webkit-animation-play-state: ' + playState);
    }),

    actions: {
      removeNotification: function removeNotification() {
        this.notifications.removeNotification(this.get('notification'));
      }
    }
  });
});