define("ember-pickr/components/color-picker", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "ember-pickr/templates/components/color-picker", "ember-pickr/utils/mergeDeep", "pickr"], function (_exports, _objectSpread2, _colorPicker, _mergeDeep, _pickr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _colorPicker.default,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this._setupPickr();
    },
    _setupPickr: function _setupPickr() {
      var _this = this;

      this._options = {
        // Start state. If true 'disabled' will be added to the button's classlist.
        disabled: this.get('disabled') || false,
        // If set to false it would directly apply the selected color on the button and preview.
        comparison: this.get('comparison') !== false,
        // Default color
        default: this.get('value') || this.get('default') || 'fff',
        // Default color representation.
        // Valid options are `HEX`, `RGBA`, `HSVA`, `HSLA` and `CMYK`.
        defaultRepresentation: this.get('defaultRepresentation') || 'HEX',
        // Option to keep the color picker always visible. You can still hide / show it via
        // 'pickr.hide()' and 'pickr.show()'. The save button keeps his functionality, so if
        // you click it, it will fire the onSave event.
        showAlways: this.get('showAlways') || false,
        // Close pickr with this specific key.
        // Default is 'Escape'. Can be the event key or code.
        closeWithKey: this.get('closeWithKey') || 'Escape',
        // Defines the position of the color-picker. Available options are
        // top, left and middle relative to the picker button.
        // If clipping occurs, the color picker will automatically choose his position.
        position: this.get('position') || 'middle',
        // Enables the ability to change numbers in an input field with the scroll-wheel.
        // To use it set the cursor on a position where a number is and scroll, use ctrl to make steps of five
        adjustableNumbers: this.get('adjustableNumbers') !== false,
        strings: {
          save: this.get('saveLabel') || 'Save',
          clear: this.get('clearLabel') || 'Clear'
        }
      };
      this._components = (0, _mergeDeep.default)({
        palette: true,
        preview: true,
        opacity: true,
        hue: true,
        interaction: {
          hex: true,
          rgba: true,
          hsva: true,
          input: true,
          clear: true,
          save: true
        }
      }, this.get('components'));
      this._pickr = _pickr.default.create((0, _objectSpread2.default)({
        el: this.element
      }, this._options, {
        components: (0, _objectSpread2.default)({}, this._components)
      }));

      this._pickr.on('init', function () {
        _this.set('_value', _this.formatColor(_this._pickr.getColor()));
      });

      this._pickr.on('save', function () {
        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        var hsva = args[0],
            instance = args[1];

        var value = _this.formatColor(hsva);

        _this.set('_value', value);

        if (_this.onSave) {
          _this.onSave(hsva, instance);
        }
      });

      this._pickr.on('change', function () {
        for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
          args[_key2] = arguments[_key2];
        }

        var hsva = args[0],
            instance = args[1];

        if (_this.onChange) {
          _this.onChange(hsva, instance);
        }
      });
    },
    value: Ember.computed('_value', {
      get: function get() {
        return this.get('_value');
      },
      set: function set(key, value) {
        if (this._pickr) {
          var currentColor = this.formatColor(this._pickr.getColor()); // This check is to avoid setting the same color twice one after another
          // Without this check, this will result in two computations for every color change

          if (currentColor !== value) {
            this._pickr.setColor(value);
          }
        }

        return value;
      }
    }),
    formatColor: function formatColor(hsva) {
      if (!hsva) {
        return null;
      }

      var value = hsva;
      var format = this.get('format');

      if (format) {
        format = format.toUpperCase(); // backward compat till next major version

        if (format === 'HEX') {
          format = 'HEXA';
        }

        (false && !(['HSVA', 'HSLA', 'RGBA', 'HEXA', 'CMYK'].includes(format)) && Ember.assert('[ember-pickr]: Format must be one of HSVA, HSLA, RGBA, HEXA, CMYK', ['HSVA', 'HSLA', 'RGBA', 'HEXA', 'CMYK'].includes(format)));
        value = value["to".concat(format)]().toString();
      }

      return value;
    },
    willDestroyElement: function willDestroyElement() {
      this._pickr.destroyAndRemove();

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});