define('ember-introjs/components/step', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    // Optionally define the number (priority) of step
    step: 0,

    // The tooltip text of step
    intro: null,

    // Optionally define a CSS class for tooltip
    tooltipClass: null,

    // Optionally append a CSS class to the helperLayer
    highlightClass: null,

    // Optionally define the position of tooltip, top, left, right,
    // bottom, bottom-left-aligned (same as bottom), bottom-middle-aligned,
    // bottom-right-aligned or auto (to detect the position of element
    // and assign the correct position automatically). Default is bottom
    position: 'bottom',

    // The tooltip text of hint
    hint: null,

    // Optionally define the position of hint. Options: top-middle, top-left,
    // top-right, bottom-left, bottom-right, bottom-middle,
    // middle-left, middle-right, middle-middle. Default: top-middle
    hintPosition: 'top-middle',

    // PRIVATE interface - INTRO JS implementation

    attributeBindings: ['data-step', 'data-intro', 'data-position', 'data-tooltipClass', 'data-highlightClass', 'data-hint', 'data-hintPosition'],

    'data-step': Ember.computed.readOnly('step'),

    'data-hint': Ember.computed.readOnly('hint'),
    'data-intro': Ember.computed.readOnly('intro'),

    'data-tooltipClass': Ember.computed.readOnly('tooltipClass'),
    'data-highlightClass': Ember.computed.readOnly('highlightClass'),

    'data-position': Ember.computed.readOnly('position'),
    'data-hintPosition': Ember.computed.readOnly('hintPosition')
  });
});