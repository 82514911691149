define("ember-pickr/utils/mergeDeep", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/typeof"], function (_exports, _defineProperty2, _typeof2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = mergeDeep;

  function isObject(item) {
    return item && (0, _typeof2.default)(item) === 'object' && !Array.isArray(item);
  }

  function mergeDeep(target) {
    for (var _len = arguments.length, sources = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      sources[_key - 1] = arguments[_key];
    }

    if (!sources.length) return target;
    var source = sources.shift();

    if (isObject(target) && isObject(source)) {
      for (var key in source) {
        if (isObject(source[key])) {
          if (!target[key]) Object.assign(target, (0, _defineProperty2.default)({}, key, {}));
          mergeDeep(target[key], source[key]);
        } else {
          Object.assign(target, (0, _defineProperty2.default)({}, key, source[key]));
        }
      }
    }

    return mergeDeep.apply(void 0, [target].concat(sources));
  }
});