define("ember-drag-sort/templates/components/drag-sort-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pfskeYA5",
    "block": "{\"symbols\":[\"item\",\"index\",\"&default\"],\"statements\":[[4,\"each\",[[23,0,[\"items\"]]],null,{\"statements\":[[0,\"  \"],[5,\"drag-sort-item\",[[12,\"class\",[23,0,[\"childClass\"]]]],[[\"@additionalArgs\",\"@item\",\"@index\",\"@items\",\"@group\",\"@handle\",\"@tagName\",\"@isHorizontal\",\"@isRtl\",\"@draggingEnabled\",\"@sourceOnly\",\"@dragEndAction\",\"@dragStartAction\",\"@determineForeignPositionAction\"],[[23,0,[\"additionalArgs\"]],[23,1,[]],[23,2,[]],[23,0,[\"items\"]],[23,0,[\"group\"]],[23,0,[\"handle\"]],[23,0,[\"childTagName\"]],[23,0,[\"isHorizontal\"]],[23,0,[\"isRtl\"]],[23,0,[\"draggingEnabled\"]],[23,0,[\"sourceOnly\"]],[23,0,[\"dragEndAction\"]],[23,0,[\"dragStartAction\"]],[23,0,[\"determineForeignPositionAction\"]]]],{\"statements\":[[0,\"\\n    \"],[14,3,[[23,1,[]],[23,2,[]]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-drag-sort/templates/components/drag-sort-list.hbs"
    }
  });

  _exports.default = _default;
});