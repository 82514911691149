define('ember-introjs/intro-js', ['exports', 'intro-js'], function (exports, _introJs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _introJs.default;
    }
  });
});