define("ember-cli-emoji/components/emoji-tag", ["exports", "ember-cli-emoji/templates/components/emoji-tag"], function (_exports, _emojiTag) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _emojiTag.default,

    /**
     * Default class name of `emoji-tag` component
     */
    classNames: ['emoji-tag'],

    /**
     * Schedule `emojify` during run loop
     */
    scheduleEmojify: function scheduleEmojify() {
      Ember.run.once(this, this.runEmojify);
    },
    runEmojify: function runEmojify() {
      emojify.run();
    },

    /**
     * Schedule `emojify` after render
     */
    didInsertElement: function didInsertElement() {
      this._super();

      this.scheduleEmojify = this.scheduleEmojify.bind(this);
      return Ember.run.scheduleOnce('afterRender', this, this.afterRenderEvent);
    },

    /**
     * Run `emojify` and schedule `emojify` whenever content changes
     */
    afterRenderEvent: function afterRenderEvent() {
      this.runEmojify();
      this.element.addEventListener("DOMSubtreeModified", this.scheduleEmojify);
    }
  });

  _exports.default = _default;
});