define("semantic-ui-ember/templates/components/ui-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jPVEq08O",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"input\",true],[11,\"name\",[22,\"name\"]],[11,\"tabindex\",[22,\"tabindex\"]],[11,\"checked\",[28,\"unbound\",[[24,[\"checked\"]]],null]],[11,\"disabled\",[28,\"unbound\",[[24,[\"disabled\"]]],null]],[11,\"type\",[22,\"type\"]],[8],[9],[0,\"\\n\"],[7,\"label\",true],[8],[1,[22,\"label\"],false],[9],[0,\"\\n\"],[14,1,[[28,\"action\",[[23,0,[]],\"execute\"],null]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "semantic-ui-ember/templates/components/ui-checkbox.hbs"
    }
  });

  _exports.default = _default;
});