define("semantic-ui-ember/helpers/map-value", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "ember-promise-utils/mixins/promise-resolver"], function (_exports, _slicedToArray2, _promiseResolver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend(_promiseResolver.default, {
    compute: function compute(_ref) {
      var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
          action = _ref2[0],
          maybePromise = _ref2[1];

      return this.resolvePromise(maybePromise, function (value) {
        return action(value);
      }, function () {
        this.recompute();
        return null;
      });
    }
  });

  _exports.default = _default;
});